import React, { useState, useEffect,useRef } from 'react';
import { Router, Route, Switch,useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import Landing from "./Landing.js"
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import Upload from "./views/Upload";
import Lists from "./views/Lists";
import Affiliate from "./views/Affiliate"
import Admin from "./views/Admin";
import Consultation from "./views/Consultation";
import ColdCallers from "./views/ColdCallers";
import ExternalApi from "./views/ExternalApi";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

import Application from "./Application";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
  useEffect(() => {
    // Function to parse query parameters
    const getQueryParams = (query) => {
      const params = new URLSearchParams(query);
      const result = {};
      for (let param of params.entries()) {
        result[param[0]] = param[1];
      }
      return result;
    };

    // Parse query parameters from the window.location.search
    const queryParams = getQueryParams(window.location.search);
    const message = queryParams.msg;

    if (message) {
      // Delay the notification by 1 second (1000 milliseconds)
      const timeoutId = setTimeout(() => {
        toast.success(message, { autoClose: 3000, theme: "colored" });

        // Remove the 'msg' parameter from the URL
        const params = new URLSearchParams(window.location.search);
        params.delete('msg');
        const newUrl = window.location.pathname + '?' + params.toString();
        window.history.replaceState({}, '', newUrl);
      }, 1000);

      // Clear the timeout if the component unmounts before the delay finishes
      return () => clearTimeout(timeoutId);
    }
  }, []);


  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
      <ToastContainer />
      <NavBar />
          <Switch>
            <Route path="/" exact component={Landing} />
            <Route path="/profile" component={Profile} />
            <Route path="/external-api" component={ExternalApi} />
            <Route path="/upload" component={Upload} />
            <Route path="/lists" component={Lists} />
            <Route path="/affiliate" component={Affiliate} />
            <Route path="/admin" component={Admin} />
            <Route path="/unlimited" component={Consultation} />
            <Route path="/coldcallers" component={ColdCallers} />
          </Switch>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
