import React, { Fragment } from "react";
const ColdCallers = () => (
  <Fragment>
   <iframe style = {{
                width: '100vw',
                height: '100vh',
                border: 'none'
            }} src = "https://0333gpaodzl.typeform.com/to/ilm753uH"></iframe>
  </Fragment>
);

export default ColdCallers;
